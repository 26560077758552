:root {
  --top-panel-padding: 20px;
  --top-panel-height: var(--b-size);
  --top-panel-social-img-width: calc(var(--m-size) / 2);
}

.top-panel {
  background-color: var(--color);
  user-select: none;
  text-align: center;
  height: var(--top-panel-height);
}

.top-panel-links-container {
  margin-left: calc(50vw - 535px / 2);
}

.top-panel-links {
  display: table;
}

.top-panel-link {
  height: var(--top-panel-height);
  text-decoration: none;
  color: var(--t-color);
  display: table-cell;
  vertical-align: middle;
  padding-left: var(--top-panel-padding);
  padding-right: var(--top-panel-padding);
  transition: color var(--transition-duration);
}

.top-panel-link:hover {
  color: var(--t-color2);
}

.top-panel-link-name {
  color: inherit;
  padding: 0;
}

.top-panel-link-active {
  color: var(--t-color2);
}

.top-panel-link-vline-container {
  height: var(--b-size);
  text-decoration: none;
  color: var(--t-color);
  display: table-cell;
  vertical-align: middle;
}

.top-panel-vline {
  border-left: 1px solid;
  height: calc(var(--m-size) / 2);
  color: var(--t-color);
}

.top-panel-link-name-container {
  float: left;
  text-decoration: none;
  cursor: pointer;
  padding-left: var(--top-panel-padding);
  padding-right: var(--top-panel-padding);
}

.top-panel-link-name-container:hover {
  color: var(--t-color2);
}

.top-panel-link-firstname {
  color: var(--t-color);
}

.top-panel-link-firstname:hover {
  color: var(--t-color);
}

.top-panel-social {
  display: table-cell;
  vertical-align: middle;
}

.tp-soc-im-container {
  width: 35px;
  height: var(--top-panel-height);
  float: left;
}

.tp-soc-im {
  float: left;
  width: 100%;
}

.tp-soc-im-mywed-container {
  width: 46px;
}

.tp-soc-im-instagram-container {
  width: 33px;
}

.tp-soc-im-telegram-container {
  width: 34px;
}

.tp-soc-im-hv {
  transition: opacity var(--transition-duration);
  position: relative;
  top: -100%;
}

.tp-soc-im-hv:hover {
  opacity: 0;
}

.top-panel-menu-icon {
  display: none;
  transition: opacity var(--transition-duration);
}

.top-panel-active-page {
  display: none;
}

.top-panel-language-switch {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;  
  margin-top: calc(var(--top-panel-height) / 4);
  margin-right: calc(var(--top-panel-height) / 4);
  width: calc(var(--top-panel-height) / 2);
  height: calc(var(--top-panel-height) / 2);
  background-color: white;
  border-radius: 3px;
  line-height: calc(var(--top-panel-height) / 2);
  transition: var(--transition-duration);
}

.top-panel-language-switch:hover {
  color: var(--color);
  background-color: var(--t-color2);
}

@media (max-width: 650px) {
  .top-panel-language-switch {
    display: none;
  }
}

@media (max-width: 565px) {
  .top-panel-links-container {
    margin-left: unset;
    display: inline-block;
  }

  .top-panel-menu-icon {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: var(--top-panel-height);
    height: var(--top-panel-height);
    z-index: 2;
  }

  .top-panel-menu {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  .top-panel-active-page {
    display: block;
  }

  .top-panel-menu-icon-hidden {
    opacity: 0;
  }

  .top-panel-menu-items {
    display: block;
    width: 100vw;
  }

  .top-panel-active-page > .top-panel-menu-items {
    height: var(--top-panel-height);
    overflow: hidden;
  }

  .top-panel-menu-page > .top-panel-menu-items {
    height: 0;
    overflow: hidden;
    transition: height var(--transition-duration);
  }

  .top-panel-menu-page > .top-panel-menu-items-opened {
    height: calc(4 * var(--top-panel-height));
  }

  .top-panel-menu-items-item {
    display: inline-block;
    width: 100%;
    padding: 0;
    line-height: var(--top-panel-height);
    background-color: var(--color);
  }

  .top-panel-menu-items-item-sub {
    display: inline-block;
    line-height: var(--top-panel-height);
  }

  .top-panel-link-name {
    display: inline-block;
    line-height: var(--top-panel-height);
  }
  
  .top-panel-active-page .top-panel-link-active {
    position: absolute;
    top: 0;
    left: 0;
  }

  .top-panel-link-active {
    display: inline-block;
    width: 100vw;
    padding: 0;
    line-height: var(--top-panel-height);
    background-color: var(--color);
  }

  .top-panel-link-active-opened {
    position: unset;
  }

  .top-panel-social {
    width: 100vw;
    background-color: var(--color);
    text-align: center;
  }

  .top-panel-social-hidden {
    display: none;
  }

  .top-panel-social-inside {
    display: inline-block;
    margin-left: -10px;
  }

  .top-panel-link-vline-container {
    display: none;
  }
}