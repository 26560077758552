.copyright {
  cursor: default;
  user-select: none;
  text-align: center;
  width: 100%;
  font-size: 14px;
  height: var(--top-panel-height);
  line-height: var(--top-panel-height);
  background-color: var(--color);
}

.portfolio-copyright {
  background-color: white;
}
