:root {
  --t-color: rgb(192, 192, 192);
  --t-color2: black;
  --color: rgb(249, 249, 249);
  --cover-color: white;
}

* {
  padding: 0;
  border: none;
  vertical-align: top;
  font-size: 18px;
  color: var(--t-color);
  line-height: 120%;
}

html {
  height: 100%;
  overflow-x: hidden;
}

@font-face {
  font-family: 'myfont';
  src: url('/font.woff') format('woff');
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'myfont';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}