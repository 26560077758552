:root {
  --t-color: rgb(192, 192, 192);
  --t-color2: black;
  --color: rgb(249, 249, 249);
  --cover-color: white;
}

* {
  padding: 0;
  border: none;
  vertical-align: top;
  font-size: 18px;
  color: rgb(192, 192, 192);
  color: var(--t-color);
  line-height: 120%;
}

html {
  height: 100%;
  overflow-x: hidden;
}

@font-face {
  font-family: 'myfont';
  src: url('/font.woff') format('woff');
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'myfont';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}
:root {
  --b-size: 50px;
  --m-size: 30px;
  --s-size: 10px;
  --transition-duration: 150ms;
}

.main {
  height: 100%;
}
:root {
  --top-panel-padding: 20px;
  --top-panel-height: var(--b-size);
  --top-panel-social-img-width: calc(var(--m-size) / 2);
}

.top-panel {
  background-color: var(--color);
  -webkit-user-select: none;
          user-select: none;
  text-align: center;
  height: var(--b-size);
  height: var(--top-panel-height);
}

.top-panel-links-container {
  margin-left: calc(50vw - 535px / 2);
}

.top-panel-links {
  display: table;
}

.top-panel-link {
  height: var(--b-size);
  height: var(--top-panel-height);
  text-decoration: none;
  color: var(--t-color);
  display: table-cell;
  vertical-align: middle;
  padding-left: 20px;
  padding-left: var(--top-panel-padding);
  padding-right: 20px;
  padding-right: var(--top-panel-padding);
  transition: color var(--transition-duration);
}

.top-panel-link:hover {
  color: var(--t-color2);
}

.top-panel-link-name {
  color: inherit;
  padding: 0;
}

.top-panel-link-active {
  color: var(--t-color2);
}

.top-panel-link-vline-container {
  height: var(--b-size);
  text-decoration: none;
  color: var(--t-color);
  display: table-cell;
  vertical-align: middle;
}

.top-panel-vline {
  border-left: 1px solid;
  height: calc(var(--m-size) / 2);
  color: var(--t-color);
}

.top-panel-link-name-container {
  float: left;
  text-decoration: none;
  cursor: pointer;
  padding-left: 20px;
  padding-left: var(--top-panel-padding);
  padding-right: 20px;
  padding-right: var(--top-panel-padding);
}

.top-panel-link-name-container:hover {
  color: var(--t-color2);
}

.top-panel-link-firstname {
  color: var(--t-color);
}

.top-panel-link-firstname:hover {
  color: var(--t-color);
}

.top-panel-social {
  display: table-cell;
  vertical-align: middle;
}

.tp-soc-im-container {
  width: 35px;
  height: var(--b-size);
  height: var(--top-panel-height);
  float: left;
}

.tp-soc-im {
  float: left;
  width: 100%;
}

.tp-soc-im-mywed-container {
  width: 46px;
}

.tp-soc-im-instagram-container {
  width: 33px;
}

.tp-soc-im-telegram-container {
  width: 34px;
}

.tp-soc-im-hv {
  transition: opacity var(--transition-duration);
  position: relative;
  top: -100%;
}

.tp-soc-im-hv:hover {
  opacity: 0;
}

.top-panel-menu-icon {
  display: none;
  transition: opacity var(--transition-duration);
}

.top-panel-active-page {
  display: none;
}

.top-panel-language-switch {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;  
  margin-top: calc(var(--b-size) / 4);  
  margin-top: calc(var(--top-panel-height) / 4);
  margin-right: calc(var(--b-size) / 4);
  margin-right: calc(var(--top-panel-height) / 4);
  width: calc(var(--b-size) / 2);
  width: calc(var(--top-panel-height) / 2);
  height: calc(var(--b-size) / 2);
  height: calc(var(--top-panel-height) / 2);
  background-color: white;
  border-radius: 3px;
  line-height: calc(var(--b-size) / 2);
  line-height: calc(var(--top-panel-height) / 2);
  transition: var(--transition-duration);
}

.top-panel-language-switch:hover {
  color: var(--color);
  background-color: var(--t-color2);
}

@media (max-width: 650px) {
  .top-panel-language-switch {
    display: none;
  }
}

@media (max-width: 565px) {
  .top-panel-links-container {
    margin-left: unset;
    display: inline-block;
  }

  .top-panel-menu-icon {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: var(--b-size);
    width: var(--top-panel-height);
    height: var(--b-size);
    height: var(--top-panel-height);
    z-index: 2;
  }

  .top-panel-menu {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  .top-panel-active-page {
    display: block;
  }

  .top-panel-menu-icon-hidden {
    opacity: 0;
  }

  .top-panel-menu-items {
    display: block;
    width: 100vw;
  }

  .top-panel-active-page > .top-panel-menu-items {
    height: var(--b-size);
    height: var(--top-panel-height);
    overflow: hidden;
  }

  .top-panel-menu-page > .top-panel-menu-items {
    height: 0;
    overflow: hidden;
    transition: height var(--transition-duration);
  }

  .top-panel-menu-page > .top-panel-menu-items-opened {
    height: calc(4 * var(--b-size));
    height: calc(4 * var(--top-panel-height));
  }

  .top-panel-menu-items-item {
    display: inline-block;
    width: 100%;
    padding: 0;
    line-height: var(--b-size);
    line-height: var(--top-panel-height);
    background-color: var(--color);
  }

  .top-panel-menu-items-item-sub {
    display: inline-block;
    line-height: var(--b-size);
    line-height: var(--top-panel-height);
  }

  .top-panel-link-name {
    display: inline-block;
    line-height: var(--b-size);
    line-height: var(--top-panel-height);
  }
  
  .top-panel-active-page .top-panel-link-active {
    position: absolute;
    top: 0;
    left: 0;
  }

  .top-panel-link-active {
    display: inline-block;
    width: 100vw;
    padding: 0;
    line-height: var(--b-size);
    line-height: var(--top-panel-height);
    background-color: var(--color);
  }

  .top-panel-link-active-opened {
    position: unset;
  }

  .top-panel-social {
    width: 100vw;
    background-color: var(--color);
    text-align: center;
  }

  .top-panel-social-hidden {
    display: none;
  }

  .top-panel-social-inside {
    display: inline-block;
    margin-left: -10px;
  }

  .top-panel-link-vline-container {
    display: none;
  }
}
.gallery {
  -webkit-user-select: none;
          user-select: none;
  text-align: center;
  height: calc(100vh - var(--b-size));
  overflow: hidden;
}

.gallery-images-container {
  height: calc(100vh - var(--b-size));
}

.gallery-image {
  position: absolute;
  width: 100vw;
  height: calc(100vh - var(--b-size));
  object-fit: cover;
  object-position: center;
}

.gallery-select-panel {
  position: relative;
  height: var(--m-size);
  top: calc(-1 * var(--m-size));
}

.gallery-circles-container {
  overflow: hidden;
  display: inline-block;
  width: calc((var(--gallery-circle-width) + var(--gallery-circle-margin) * 2) * (var(--gallery-main-circles-count) + var(--gallery-side-circles-count) * 2));
  white-space: nowrap;
}

.gallery-circle-container {
  cursor: pointer;
  display: inline-block;
  margin-left: var(--gallery-circle-margin);
  margin-right: var(--gallery-circle-margin);
}

.gallery-circle {
  width: var(--gallery-circle-width);
  height: var(--gallery-circle-width);
  background-color: rgba(251, 251, 251, 0.3);
  border-radius: 50%;
}

.gallery-circle-active {
  background-color: rgba(251, 251, 251, 0.9);
}

.gallery-image-nav {
  cursor: pointer;
  position: absolute;
  top: var(--top-panel-height);
  bottom: 0;
  width: 25%;
}

.gallery-image-next {
  right: 0;
}
:root {
  --weddings-wedding-width: 280px;
  --weddings-wedding-border-radius: 10px;
}

.portfolio {
  min-height: calc(100% - var(--top-panel-height) * 2);
}

.portfolio-container {
  -webkit-user-select: none;
          user-select: none;
  text-align: center;
}

.portfolio-container-photosessions {
  background-color: var(--color);
}

.weddings {
  margin-top: var(--b-size);
  margin-bottom: var(--b-size);
  width: 90%;
  display: inline-block;
}

.photosessions {
  margin-top: var(--b-size);
  margin-bottom: var(--b-size);
  width: 90%;
  display: inline-block;
}

.weddings-row {
  height: 280px;
  height: var(--weddings-wedding-width);
  display: inline-block;
  margin-bottom: calc(var(--b-size) / 2);
}

.weddings-row:last-child {
  margin-bottom: 0;
}

.weddings-wedding-link {
  height: 100%;
  float: left;
  text-decoration: none;
  width: 280px;
  width: var(--weddings-wedding-width);
  margin-left: calc(var(--b-size) / 4);
  margin-right: calc(var(--b-size) / 4);
}

.weddings-wedding {
  height: 100%;
}

.weddings-wedding-cover {
  border-radius: 10px;
  border-radius: var(--weddings-wedding-border-radius);
  width: 280px;
  width: var(--weddings-wedding-width);
  height: 280px;
  height: var(--weddings-wedding-width);
}

.weddings-wedding-hover {
  opacity: 0;
  float: left;
  width: 100%;
  height: 100%;
  margin-top: -100%;
  transition: opacity var(--transition-duration);
}

.weddings-wedding-hover:hover {
  opacity: 1;
}

.weddings-wedding-name {
  position: relative;
  top: -50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: var(--cover-color);
  font-size: 25px;
  padding-left: 25px;
  padding-right: 25px;
}

.weddings-wedding-dark {
  opacity: 0.6;
  border-radius: 10px;
  border-radius: var(--weddings-wedding-border-radius);
  background-color: black;
  width: 100%;
  height: 100%;
}

@media (max-width: 565px) {
  .weddings, .photosessions {
    width: 100%;
  }

  .weddings-wedding-link {
    float: none;
    display: inline-block;
    margin: 0;
  }
}
.copyright {
  cursor: default;
  -webkit-user-select: none;
          user-select: none;
  text-align: center;
  width: 100%;
  font-size: 14px;
  height: var(--top-panel-height);
  line-height: var(--top-panel-height);
  background-color: var(--color);
}

.portfolio-copyright {
  background-color: white;
}

.contacts {
  min-height: calc(100% - var(--top-panel-height) * 2 - var(--b-size) * 2);
  text-align: center;
  margin-top: var(--b-size);
  margin-bottom: var(--b-size);
}

.contacts-photo-container {
  -webkit-user-select: none;
          user-select: none;
  display: inline-block;
  width: var(--weddings-wedding-width);
  height: var(--weddings-wedding-width);
}

.contacts-photo {
  cursor: pointer;
  border-radius: var(--weddings-wedding-border-radius);
  width: 100%;
  transition: opacity var(--transition-duration);
}

.contacts-photo:last-child {
  margin-top: -100%;
}

.contacts-photo-hided {
  opacity: 0;
}

.contacts-data {
  margin-top: calc(var(--b-size) / 2);
}

.contacts-contact {
  line-height: 150%;
  color: var(--t-color2);
}

.contacts-link {
  line-height: 150%;
  color: var(--t-color2);
  text-decoration: none;
}

.contacts-link:hover {
  text-decoration: underline;
}

:root {
  --wedding-image-margin: 4px;
}

.wedding {
  -webkit-user-select: none;
          user-select: none;
  margin-top: var(--b-size);
  text-align: center;
}

.wedding-name {
  cursor: default;
  position: relative;
  left: 50vw;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  font-size: 30px;
  margin-bottom: var(--b-size);
  color: var(--t-color2);
}

.wedding-row {
  display: inline-block;
  margin-top: calc(4px / 2);
  margin-top: calc(var(--wedding-image-margin) / 2);
  margin-bottom: calc(4px / 2);
  margin-bottom: calc(var(--wedding-image-margin) / 2);
  white-space: nowrap;
}

.wedding-row:first-child {
  margin-top: 0;
}

.wedding-row:last-child {
  margin-bottom: 0;
}

.wedding-image {
  margin-left: calc(4px / 2);
  margin-left: calc(var(--wedding-image-margin) / 2);
  margin-right: calc(4px / 2);
  margin-right: calc(var(--wedding-image-margin) / 2);
  background-color: var(--t-color);
}

.wedding-image:first-child {
  margin-left: 0;
}

.wedding-image:last-child {
  margin-right: 0;
}
