:root {
  --wedding-image-margin: 4px;
}

.wedding {
  user-select: none;
  margin-top: var(--b-size);
  text-align: center;
}

.wedding-name {
  cursor: default;
  position: relative;
  left: 50vw;
  transform: translate(-50%, 0);
  font-size: 30px;
  margin-bottom: var(--b-size);
  color: var(--t-color2);
}

.wedding-row {
  display: inline-block;
  margin-top: calc(var(--wedding-image-margin) / 2);
  margin-bottom: calc(var(--wedding-image-margin) / 2);
  white-space: nowrap;
}

.wedding-row:first-child {
  margin-top: 0;
}

.wedding-row:last-child {
  margin-bottom: 0;
}

.wedding-image {
  margin-left: calc(var(--wedding-image-margin) / 2);
  margin-right: calc(var(--wedding-image-margin) / 2);
  background-color: var(--t-color);
}

.wedding-image:first-child {
  margin-left: 0;
}

.wedding-image:last-child {
  margin-right: 0;
}