.contacts {
  min-height: calc(100% - var(--top-panel-height) * 2 - var(--b-size) * 2);
  text-align: center;
  margin-top: var(--b-size);
  margin-bottom: var(--b-size);
}

.contacts-photo-container {
  user-select: none;
  display: inline-block;
  width: var(--weddings-wedding-width);
  height: var(--weddings-wedding-width);
}

.contacts-photo {
  cursor: pointer;
  border-radius: var(--weddings-wedding-border-radius);
  width: 100%;
  transition: opacity var(--transition-duration);
}

.contacts-photo:last-child {
  margin-top: -100%;
}

.contacts-photo-hided {
  opacity: 0;
}

.contacts-data {
  margin-top: calc(var(--b-size) / 2);
}

.contacts-contact {
  line-height: 150%;
  color: var(--t-color2);
}

.contacts-link {
  line-height: 150%;
  color: var(--t-color2);
  text-decoration: none;
}

.contacts-link:hover {
  text-decoration: underline;
}
