:root {
  --weddings-wedding-width: 280px;
  --weddings-wedding-border-radius: 10px;
}

.portfolio {
  min-height: calc(100% - var(--top-panel-height) * 2);
}

.portfolio-container {
  user-select: none;
  text-align: center;
}

.portfolio-container-photosessions {
  background-color: var(--color);
}

.weddings {
  margin-top: var(--b-size);
  margin-bottom: var(--b-size);
  width: 90%;
  display: inline-block;
}

.photosessions {
  margin-top: var(--b-size);
  margin-bottom: var(--b-size);
  width: 90%;
  display: inline-block;
}

.weddings-row {
  height: var(--weddings-wedding-width);
  display: inline-block;
  margin-bottom: calc(var(--b-size) / 2);
}

.weddings-row:last-child {
  margin-bottom: 0;
}

.weddings-wedding-link {
  height: 100%;
  float: left;
  text-decoration: none;
  width: var(--weddings-wedding-width);
  margin-left: calc(var(--b-size) / 4);
  margin-right: calc(var(--b-size) / 4);
}

.weddings-wedding {
  height: 100%;
}

.weddings-wedding-cover {
  border-radius: var(--weddings-wedding-border-radius);
  width: var(--weddings-wedding-width);
  height: var(--weddings-wedding-width);
}

.weddings-wedding-hover {
  opacity: 0;
  float: left;
  width: 100%;
  height: 100%;
  margin-top: -100%;
  transition: opacity var(--transition-duration);
}

.weddings-wedding-hover:hover {
  opacity: 1;
}

.weddings-wedding-name {
  position: relative;
  top: -50%;
  transform: translateY(-50%);
  color: var(--cover-color);
  font-size: 25px;
  padding-left: 25px;
  padding-right: 25px;
}

.weddings-wedding-dark {
  opacity: 0.6;
  border-radius: var(--weddings-wedding-border-radius);
  background-color: black;
  width: 100%;
  height: 100%;
}

@media (max-width: 565px) {
  .weddings, .photosessions {
    width: 100%;
  }

  .weddings-wedding-link {
    float: none;
    display: inline-block;
    margin: 0;
  }
}