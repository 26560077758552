.gallery {
  user-select: none;
  text-align: center;
  height: calc(100vh - var(--b-size));
  overflow: hidden;
}

.gallery-images-container {
  height: calc(100vh - var(--b-size));
}

.gallery-image {
  position: absolute;
  width: 100vw;
  height: calc(100vh - var(--b-size));
  object-fit: cover;
  object-position: center;
}

.gallery-select-panel {
  position: relative;
  height: var(--m-size);
  top: calc(-1 * var(--m-size));
}

.gallery-circles-container {
  overflow: hidden;
  display: inline-block;
  width: calc((var(--gallery-circle-width) + var(--gallery-circle-margin) * 2) * (var(--gallery-main-circles-count) + var(--gallery-side-circles-count) * 2));
  white-space: nowrap;
}

.gallery-circle-container {
  cursor: pointer;
  display: inline-block;
  margin-left: var(--gallery-circle-margin);
  margin-right: var(--gallery-circle-margin);
}

.gallery-circle {
  width: var(--gallery-circle-width);
  height: var(--gallery-circle-width);
  background-color: rgba(251, 251, 251, 0.3);
  border-radius: 50%;
}

.gallery-circle-active {
  background-color: rgba(251, 251, 251, 0.9);
}

.gallery-image-nav {
  cursor: pointer;
  position: absolute;
  top: var(--top-panel-height);
  bottom: 0;
  width: 25%;
}

.gallery-image-next {
  right: 0;
}